import jwt_decode from 'jwt-decode';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
const axios = require('axios')

export function emailValid(eml) {
  return /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/.test(eml)
}

export class passwordRequirements {
  constructor(password, confirmPassword) {
    this.password = password
    this.confirmPassword = confirmPassword

    this.minPasswordLength = 10
    this.requirements = {
      lengthMet: {
        check: () => {
          return this.password.length >= this.minPasswordLength
        },
        description: "At least " + this.minPasswordLength + " characters long"
      },
      containsLowercase: {
        check: () => {
          return this.password.match(/[a-z]/) !== null
        },
        description: "Contains at least 1 lowercase letter"
      },
      containsUppercase: {
        check: () => {
          return this.password.match(/[A-Z]/) !== null
        },
        description: "Contains at least 1 uppercase letter"
      },
      containsNumber: {
        check: () => {
          return this.password.match(/[0-9]/) !== null
        },
        description: "Contains at least 1 number"
      },
      containsSpecial: {
        check: () => {
          return this.password.match(/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`]/) !== null
        },
        description: "Contains at least 1 special character"
      },
      passwordsMatch: {
        check: () => {
          return this.password.length > 0 && this.password === this.confirmPassword
        },
        description: "Passwords match"
      },
    }
  }

  allPasswordRequirementsMet = () => {
    let returnValue = true
    let keys = Object.keys(this.requirements)
    for (let i = 0; i < keys.length; i++) {
      returnValue = returnValue && this.requirements[keys[i]].check()
    }

    return returnValue
  }
}

export function getConfig() {
  if (window.location.hostname === 'localhost') {
    console.log('using local config')
    return new Promise((resolve, reject) => {
      try {
        const localConfig = require('../config.json')
        resolve(localConfig)
      } catch {
        resolve({
          "region": "us-east-1",
          "cognito_user_pool_id": "<user pool ID>",
          "cognito_user_pool_client_id": "<client ID>"
        })
      }
    })
  } else {
    return axios.get('/config.json')
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.error('error retrieving config:', err)
      throw err
    })
  }
}

export function getAccessToken() {
  return sessionStorage.getItem('access_token') || localStorage.getItem('access_token')
}

export function getIdToken() {
  return sessionStorage.getItem('id_token') || localStorage.getItem('id_token')
}

export function getRefreshToken() {
  return sessionStorage.getItem('refresh_token') || localStorage.getItem('refresh_token')
}

export function tokensAreExpired() {
  let tokensExpired = false
  let tokens = [getAccessToken(), getIdToken()]

  for (let i = 0; i < tokens.length; i++) {
    let token = tokens[i]

    if (token === null) {
      console.log('Token missing')
      tokensExpired = true
      break
    }

    const token_content = jwt_decode(token)

    if (token_content.exp <= Math.floor(Date.now() / 1000)) {
      console.log('Token is expired')
      tokensExpired = true
      break
    }
  }

  return tokensExpired
}

export function refreshTokens() {
  const refreshToken = getRefreshToken()

  return getConfig()
  .then((config) => {
    const client = new CognitoIdentityProviderClient({region: config.region});
    const command = new InitiateAuthCommand({
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken
      },
      ClientId: config.cognito_user_pool_client_id
    })

    return client.send(command)
  })
  .then((data) => {
    console.log('Successfully refreshed tokens')

    if (localStorage.getItem('access_token') !== null) {
      localStorage.setItem('access_token', data.AuthenticationResult.AccessToken)
      localStorage.setItem('id_token', data.AuthenticationResult.IdToken)
    } else {
      sessionStorage.setItem('access_token', data.AuthenticationResult.AccessToken)
      sessionStorage.setItem('id_token', data.AuthenticationResult.IdToken)
    }

    return
  })
  .catch((error) => {
    console.error('Error occurred during token refresh:', error)
    throw error
  })
}

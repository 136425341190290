import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';
import { getConfig } from './util/helpers';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const App = React.lazy(() => import('./App'))

const nonce = Buffer.from(uuidv4()).toString('base64')
const useStyles = makeStyles((theme) => ({
  loading: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
}));

function AppRoutes() {
  const classes = useStyles();

  return (
    <Router>
      <Suspense fallback={<div className={classes.loading}><LinearProgress /></div>}>
        <Switch>
          <Route path='/' exact strict component={App} />
          <Route path='/logout' render={() => {
            localStorage.clear()
            sessionStorage.clear()

            return <Redirect to='/' />
          }} />
          <Route render={() => <Redirect to='/' />} />
        </Switch>
      </Suspense>
    </Router>
  )
}

getConfig()
.then((config) => {
  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Helmet>
          <meta http-equiv="Content-Security-Policy" content={`default-src 'self' https://cognito-idp.${config.region}.amazonaws.com/; style-src 'self' 'nonce-${nonce}';`} />
          <meta property="csp-nonce" content={nonce} />
        </Helmet>
        <AppRoutes />
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
